<template>
  <v-container fluid>
    <v-flex>
      <v-card>
        <v-toolbar color="#0f2e8c" flat>
          <h3 class="white--text">Khách hàng tiềm năng</h3>
          <v-spacer />
          <v-btn
            color="green"
            dark
            @click="boxAdd=true"
          >
            Thêm
          </v-btn>
        </v-toolbar>
        <v-card-title>
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="filter['search']"
                label="Tìm kiếm"
                clearable
                class="mx-1"
              />
            </v-col>
            <v-col cols="4">
              <v-select
                  v-model="filterStatus"
                  :items="StatusData"
                  label="Trạng thái"
                  class="ma-1"
                  multiple
              />
            </v-col>
          </v-row>
          <v-btn
            class="mx-2"
            color="green"
            @click="doSearch()"
            dark
          >
            Tìm kiếm
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
              :loading="loading"
              :headers="headers"
              :options.sync="options"
              :server-items-length="totalItems"
              :items="items"
              loading-text="Đang tải dữ liệu..."
              :footer-props="{itemsPerPageText: 'Dữ liệu mỗi trang','items-per-page-options':[10,20,30,40,50]}"
              :items-per-page="20"
              @pagination="doDownloadList()"
          >
            <template v-slot:[`item.Staff`]="{ item }" v-if="loginInfo._perm.toString() === 'admin'">
              <template v-if="item.Staff">
                {{item['Staff']['Username']}}
              </template>
            </template>
            <template v-slot:[`item.Customer`]="{ item }">
              <template v-if="item.Customer">
                {{item['Customer']['Username']}}
              </template>
              <template v-else>
                <v-btn
                  color="green"
                  dark
                  small
                  @click="doCreateCustomer(item)"
                >
                  Tạo tài khoản khách hàng
                </v-btn>
              </template>
            </template>
            <template v-slot:[`item.ThongTin`]="{ item }">
              <v-row>
                <v-col cols="auto" v-if="item['FB'] || item['LinkFB']">
                  <v-btn
                    color="blue"
                    dark
                    small
                    @click="doLoadDataContact(item)"
                  >
                    FB
                  </v-btn>
                </v-col>
                <v-col cols="auto" v-if="item['Zalo']">
                  <v-btn
                      color="blue"
                      dark
                      small
                      @click="doLoadDataContact(item)"
                  >
                    Zalo
                  </v-btn>
                </v-col>
                <v-col cols="auto" v-if="item['Phone']">
                  <v-btn
                      color="blue"
                      dark
                      small
                      @click="doLoadDataContact(item)"
                  >
                    SĐT
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.Status`]="{ item }">
              <v-row>
                <v-col cols="10">
                  <v-chip
                    class="ma-1"
                    small
                    color="blue"
                    v-for="(d,index) in item['Status']"
                    :key="index"
                    dark
                  >
                    {{StatusDataObject[d]}}
                  </v-chip>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    small
                    icon
                    color="blue"
                    dark
                    @click="doLoadDataStatus(item)"
                  >
                    <v-icon>
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>


            </template>
            <template v-slot:[`item.Notes`]="{ item }">
              <v-btn
                  :color="item['Notes'].length > 0 ? 'green':'blue'"
                  @click="doGetNote(item)"
                  dark
                  small
              >
                GHI CHÚ {{item['Notes'].length > 0 ? item['Notes'][0]['Staff']['Username'] : ''}}
              </v-btn>
            </template>
            <template v-slot:[`item.Action`]="{ item }">
              <v-btn
                class="ma-1"
                color="red"
                x-small
                dark
                @click="doDelete(item)"
              >
                Xoá
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <Loader :status="loading" />
    </v-flex>
    <v-dialog
      v-model="boxUpdateInfo"
      persistent
      max-width="600px"
    >
      <v-card flat>
        <v-card-title>
          <span class="headline">Cập nhật thông tin</span>
        </v-card-title>
        <v-card-text>
          <template v-if="Customer['Status']">
            <v-select
                v-model="Customer['Status']"
                :items="StatusData"
                label="Trạng thái"
                class="ma-1"
                multiple
            />
          </template>
          <template v-else>
            <v-text-field
                v-model="Customer['FB']"
                label="Facebook"
                class="ma-1"
            />
            <v-text-field
                v-model="Customer['LinkFB']"
                label="Link Facebook"
                class="ma-1"
            />
            <v-text-field
                v-model="Customer['Phone']"
                label="Số điện thoại"
                class="ma-1"
            />
            <v-text-field
                v-model="Customer['Zalo']"
                label="Zalo"
                class="ma-1"
            />
          </template>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="green"
            dark
            @click="doUpdate()"
          >
            Lưu
          </v-btn>
          <v-spacer />
          <v-btn
            dark
            @click="boxUpdateInfo = false"
          >
            Hủy
          </v-btn>
        </v-card-actions>
      </v-card>
      <Loader :status="updateState" />
    </v-dialog>
    <v-dialog
      v-model="boxAdd"
      max-width="600px"
      persistent
    >
      <v-card flat>
        <v-card-title>
          <span class="headline">Thêm khách hàng tiềm năng</span>
        </v-card-title>
        <v-card-text>
          <v-text-field
              v-model="Customer['Ten']"
              label="Tên khách hàng"
              class="ma-1"
          />
          <v-text-field
              v-model="Customer['FB']"
              label="Facebook"
              class="ma-1"
          />
          <v-text-field
              v-model="Customer['LinkFB']"
              label="Link Facebook"
              class="ma-1"
          />
          <v-text-field
              v-model="Customer['Phone']"
              label="Số điện thoại"
              class="ma-1"
          />
          <v-text-field
              v-model="Customer['Zalo']"
              label="Zalo"
              class="ma-1"
          />
          <v-textarea
              v-model="Customer['Source']"
              label="Nguồn tiếp cận"
              class="ma-1"
          />
          <v-select
              v-model="Customer['Status']"
              :items="StatusData"
              label="Trạng thái"
              class="ma-1"
              multiple
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="green"
            dark
            @click="doCreate()"
          >
            Thêm
          </v-btn>
          <v-spacer />
          <v-btn
            dark
            @click="boxAdd = false"
          >
            Huỷ
          </v-btn>
        </v-card-actions>
      </v-card>
      <Loader :status="createState" />
    </v-dialog>
    <Add :enable.sync="boxAddCustomer" :submitForm="doAddCustomer" />
    <v-dialog
        v-model="boxNote"
        max-width="800px"
        persistent
    >
      <v-card>
        <v-card-title>
          THÔNG TIN GHI CHÚ KH: <strong>{{NoteData['Title']}}</strong>
          <v-spacer />
          <v-btn
              dark
              @click="boxNote = false"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-responsive
              v-if="NoteData.Notes.length > 0"
              class="overflow-y-hidden fill-height"
              height="500"
          >
            <v-list three-line>
              <template v-for="(item, index) in NoteData.Notes">
                <v-list-item
                    :key="index"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-chip
                          color="green"
                          dark
                          text
                          small
                      >
                        {{item['By']}}
                      </v-chip>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{item['Content']}}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text>
                      <v-chip
                          color="pink"
                          class="text-right"
                          small
                          dark
                      >
                        {{new Date(item['Date']).toLocaleString()}}
                      </v-chip>
                    </v-list-item-action-text>
                    <br />
                    <v-list-item-action-text>
                      <v-chip
                          color="green"
                          x-small
                          dark
                      >
                        {{item['By']}}
                      </v-chip>
                    </v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
                <v-divider />
              </template>
            </v-list>
          </v-responsive>
          <template v-else>
            {{ noteState ? 'Đang tải nội dung':'Chưa có nội dung'}}
          </template>
        </v-card-text>
        <v-card-text class="flex-shrink-1">
          <v-text-field
              v-model="NewNoteContent"
              label="Nội dung"
              type="text"
              no-details
              outlined
              append-outer-icon="mdi-send"
              @keyup.enter="doAddNote()"
              @click:append-outer="doAddNote()"
              hide-details
          />
        </v-card-text>
      </v-card>
      <loading :status="noteState" />
    </v-dialog>
  </v-container>
</template>

<script>
import Loader from '@/components/base/Loading';
import {serialize} from "@/plugins/helper/object";
import {pcustomer, customer} from '@/plugins/query/global';
import {Status} from "@/plugins/helper/dstatus";
import Add from '@/components/customer/add.vue';
import {mapGetters} from "vuex";
export default {
  name: "KhachHangTiemNang",
  components:{
    Loader,
    Add,
  },
  data(){
    return {
      filterStatus: null,
      search: null,
      noteState: false,
      boxNote: false,
      editID: null,
      boxAddCustomer: false,
      boxUpdateStatus: false,
      updateState: false,
      boxUpdateInfo: false,
      createState: false,
      loading: false,
      items: [],
      boxAdd: false,
      Customer:{},
      options: {},
      filter: {},
      NoteData:{
        ID: null,
        Title:null,
        Notes:[]
      },
      NewNoteContent: null,
      totalItems: 0,
    }
  },
  computed:{
    ...mapGetters(['authStatus','isAuthenticated','loginInfo']),
    headers(){
      let headers = [
        { text: 'Tên khách hàng', value: 'Ten', align: 'center',width: "200px",class:"grey lighten-2 black--text", sortable: false },
        { text: 'Thông tin LL', value: 'ThongTin',  align: 'center',width: "200px",class:"grey lighten-2 black--text", sortable: false },
        { text: 'Thông tin', value: 'Status',  align: 'center',width: "300px",class:"grey lighten-2 black--text", sortable: false },
        { text: 'Nguồn tiếp cận', value: 'Source',  align: 'center',width: "200px",class:"grey lighten-2 black--text", sortable: false },
        { text: 'Ghi chú', value: 'Notes',  align: 'center',width: "200px",class:"grey lighten-2 black--text", sortable: false },
        { text: 'Mã khách hàng', value: 'Customer',  align: 'center',width: "200px",class:"grey lighten-2 black--text", sortable: false },
      ]
      if(this.loginInfo._perm === 'admin'){
        headers.push({ text: 'Sale', value: 'Staff',  align: 'center',width: "200px",class:"grey lighten-2 black--text", sortable: false });
      }
      headers.push({ text: 'Hành động', value: 'Action',  align: 'center',width: "200px",class:"grey lighten-2 black--text", sortable: false });
      return headers;
    },
    StatusDataObject(){
      let object = {};
      this.StatusData.forEach(item=>{
        object[item.value] = item.text;
      })
      return object;
    },
    StatusData(){
      return [
        {text:'Quan tâm', value:'quan_tam'},
        {text:'Sàn khác', value:'san_khac'},
        {text:'Cá nhân', value:'ca_nhan'},
        {text:'Buôn bán', value:'buon_ban'},
      ]
    },
    notice(){
      return new Status(this.$swal);
    },
  },
  watch:{
    options:{
      handler(){
        this.doDownloadList();
      },
      deep: true,
    },
  },
  methods:{
    doSearch(){
      const {filterStatus} = this;
      if(filterStatus){
        this.filter.status = filterStatus.join(',');
      }
      this.doDownloadList();
    },
    doGetNote(item){
      this.NoteData = {
        ID: item._id,
        Title: item['Ten'],
        Notes: [],
      };
      this.boxNote = true;
      this.doLoadNote();
    },
    doLoadNote(){
      const {NoteData} = this;
      if(NoteData.ID){
        const app = this;
        app.noteState = true;
        return pcustomer.fetch(`notes/${NoteData.ID}`).then(response =>{
          return response.json();
        }).then(json=>{
          app.NoteData['Notes'] = json.data;
        }).catch(e=>{
          console.error(e);
          app.notice.error('Không thể tải thông tin ghi chú !!!');
          app.boxNote = false;
        }).finally(()=>{
          app.noteState = false;
        })
      }
    },
    doAddNote(){
      const {NewNoteContent, NoteData} = this;
      if(NewNoteContent){
        const request = {
          note: NewNoteContent
        };
        const app = this;
        app.noteState = true;
        return pcustomer.put_data(`notes/${NoteData.ID}`,request).then(response=>{
          return response.json();
        })
        .then(json=>{
          const {success, error} = json;
          if(error){
            app.notice.error(error);
          }
          if(success){
            app['NewNoteContent'] = null;
            setTimeout(app.doLoadNote(),100);
          }
        })
        .catch(e=>{
          console.error(e);
          app.notice.error('Không thể thêm ghi chú !!!');
        })
        .finally(()=>{
          app.noteState = false;
        })
      }else{
        this.notice.error('Không có nội dung ghi chú !!!');
      }
    },
    doCreateCustomer(item){
      this.editID = item._id;
      this.boxAddCustomer = true;
    },
    doAddCustomer(data){
      let newData = {
        ...data,
        PCustomer: this.editID,
      }
      return customer.create(newData).then(resp=>{
        return resp.json()}).then(json=>{
        if(json.success === true){
          this.boxAddCustomer = false;
          this.editID = null;
          this.notice.success("Đã thêm mới dữ liệu thành công !!!")
          this.doDownloadList();
        }else{
          const msg = json.error || "Không thể thêm mới khách hàng !!!"
          this.notice.error(msg)
        }
      })
    },
    doUpdate(){
      const id = this.Customer['_id'];
      let updateData = {
        ...this.Customer,
      }
      delete updateData['_id'];
      this.updateState = true;
      return pcustomer.patch(`update/${id}`, updateData).then(resp=>resp.json()).then(json=>{
        const {success, error} = json;
        if(success){
          this.boxUpdateInfo = false;
          this.doDownloadList();
          this.Customer = {};
          this.notice.success('Cập nhật thành công');
        }
        if(error){
          this.notice.error(error);
        }
      }).catch(e=>{
        console.error(e);
        this.notice.error(e.message);
      }).finally(()=>{
        this.updateState = false;
      });
    },
    doLoadDataStatus(item){
      const {Status, _id} = item;
      this.Customer = {
        Status,
        _id,
      }
      this.boxUpdateInfo = true;
    },
    doLoadDataContact(item){
      const {FB, LinkFB, Zalo, Phone, _id} = item;
      this.Customer = {
        FB,
        LinkFB,
        Zalo,
        Phone,
        _id,
      }
      this.boxUpdateInfo = true;
    },
    doDelete(item){
      const app = this;
      return this.notice.confirm("Xác nhận ?", "Hành động này không thể phục hồi !!!").then(result=>{
        if(result === true){
          app.loading = true;
          return pcustomer.delete(item._id).finally(()=>{
            app.loading = false;
            return app.doDownloadList();
          })
        }
      })
    },
    doCreate(){
      this.createState = true;
      return pcustomer.put_data('create', this.Customer).then(res=>{
        return res.json()}).then(json=>{
        const {success, error} = json;
        if(success) {
          this.notice.success('Thêm thành công');
          this.boxAdd = false;
          this.Customer = {};
          this.doDownloadList();
        }
        if(error){
          this.notice.error(error);
        }
      }).catch(err=>{
        this.notice.error(err.message);
      }).finally(()=>{
        this.createState = false;
      });
    },
    doDownloadList(){
      this.loading = true;
      const {page, itemsPerPage } = this.options;
      const queryData = {
        ...this.filter,
        page,
        itemsPerPage,
      }
      const query = serialize(queryData);
      return pcustomer.fetch(`fetch?${query}`).then(res =>{
        return res.json();
      }).then(json=>{
        this.items = json.data;
        this.totalItems = json.total;
      }).catch(e=>{
        console.error(e);
        this.notice.error(e.message);
      }).finally(()=>{
        this.loading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>