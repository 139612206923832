<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="900px"
      transition="dialog-bottom-transition"
    >
      <v-card>
      <v-form v-model="valid">
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Thêm khách hàng mới</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="submitForm(submitData)"
              :disabled="!valid"
            >
              Thêm
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list
          three-line
          subheader
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title><h3>Thông tin tài khoản JP</h3></v-list-item-title>
              <v-list-item-subtitle>
                <br />
                <v-row>
                  <v-col cols=12 lg=4 xl=4>
                    <v-text-field 
                      v-model="submitData.NameJP"
                      label="Tên JP"
                      :rules="[required('Tên JP')]"
                      @change="GenName"
                    />
                  </v-col>
                  <v-col cols=12 lg=4 xl=4>
                    <v-text-field 
                      v-model="submitData.PhoneJP"
                      label="Số điện thoại JP"
                      @change="GenPhone"
                    />
                  </v-col>
                  <v-col cols=12 lg=4 xl=4>
                    <v-text-field 
                      v-model="submitData.AddressJP"
                      label="Địa chỉ JP"
                    />
                  </v-col>
                  <v-col cols=12 lg=4 xl=4>
                    <v-text-field 
                      v-model="submitData.Shipment"
                      label="Đơn giá"
                    />
                  </v-col>
                  <v-col cols=12 lg=4 xl=4>
                    <v-text-field 
                      v-model="submitData.Username"
                      label="Tài khoản"
                    />
                  </v-col>
                  <v-col cols=12 lg=4 xl=4>
                    <v-text-field 
                      v-model="submitData.Password"
                      label="Mật khẩu"
                    />
                  </v-col>
                  <v-col
                    cols=12
                    lg=4
                    xl=4
                    v-if="loginInfo._perm === 'admin'"
                  >
                    <v-select
                      v-model="submitData.Sale"
                      :items="Sales"
                      item-value="_id"
                      item-text="Username"
                      label="Danh sách Sale"
                    />
                  </v-col>
                </v-row>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        </v-form>
        <v-overlay
          absolute
          :value="loading"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            v-if="loading"
          />
        </v-overlay>
      </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import validations from '@/plugins/helper/validations'
import { Status } from '@/plugins/helper/dstatus'
import { Request } from '@/plugins/helper/fetch'
import { xoadau } from '@/plugins/helper/string'
// import Address from '@/components/customer/listaddvn.vue'
export default {
  components: {
    // Address,
  },
  data () {
    return {
      notice: new Status(this.$swal),
      req: new Request("staff"),
      loading: false,
      valid: false,
      dialog: false,
      itemsPerPage: 5,
      page: 1,
      submitData:{
        Username: '',
        Password: 'tom123@@',
        NameJP: '',
        PhoneJP: '',
        Shipment: 75000,
        Vietnam: [],
        Sale: '',
      },
      search: '',
      newAddress:{
        ID: 0,
        Name: '',
        Phone: '',
        Address: ''
      },
      Sales: [],
      newAddressList: [],
    }
  },
  computed: {
    ...mapGetters(['authStatus','isAuthenticated','loginInfo']),
    numberOfPages () {
      const total = Math.ceil(this.newAddressList.length / this.itemsPerPage)
      return (total > 0) ? total : 1
    },
  },
  watch: {
    enable: function(val){
      this.dialog = val
    },
    dialog: function(val){
      this.$emit("update:enable", val);
    },
    newAddressList: function(val){
      this.submitData.Vietnam = val
    }
  },
  methods: {
    ...validations,
    getSales(){
      this.loading = true
      this.req.fetch("sales").then(async resp=>{
        const json = await resp.json()
        if(json.data) this.Sales = json.data
      })
      .catch(e=>{
        this.notice.error(e)
      })
      .finally(()=>{
        this.loading = false
      })
    },
    nextPage () {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage () {
      if (this.page - 1 >= 1) this.page -= 1
    },
    GenName(){
      const NameJP = this.submitData.NameJP.split(' ')
      this.submitData.Username = xoadau(NameJP[NameJP.length-1])
    },
    GenPhone(){
      const LastPhone = this.submitData.PhoneJP.substr(this.submitData.PhoneJP.length - 4);
      this.submitData.Username = this.submitData.Username+LastPhone
    },
    AddVN() {
      this.newAddress.ID = this.newAddressList.length
      if(this.newAddress.Name === '' || this.newAddress.Phone === '' || this.newAddress.Address === ''){
        this.notice.error("Điền đầy đủ thông tin !!!")
        return
      }
      this.newAddressList.push(this.newAddress)
      this.newAddressList.sort()
      this.newAddress = { Name: '', Phone: '', Address: '' }
    },
  },
  mounted(){
    if(this.loginInfo._perm === 'admin'){
      this.getSales();
    }
  },
  props: ["enable","submitForm","packageData"]
}
</script>

<style>

</style>